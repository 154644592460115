import { defineComponent, computed, watch, onMounted, onUnmounted, ref, reactive } from '@vue/composition-api';
import Loader from '@/components/Loader.vue';
import SocialNetworks from '@/Organisation/components/SocialNetworks.vue';
import OrganisationLocations from '@/Organisation/components/OrganisationLocations.vue';
import HoverButton from '@/Organisation/components/HoverButton.vue';
import { useGetCollectionQuery } from '@/generated-types/graphql.types';
import { useGetLocalizedPath } from '@/util/globalHelpers';
import { useMeta } from '@/shared/composables/useMeta';
import { OrganisationRoutes } from '@/Organisation/organisation.const';
import { SITE_URL } from '@/App.vue';
import useStructuredData from "@/shared/composables/useStructuredData";
import EventBus from "@/shared/services/eventBus";
export default defineComponent({
    components: {
        Loader,
        SocialNetworks,
        HoverButton,
        OrganisationLocations
    },
    setup(_, context) {
        const { root } = context;
        const showButtons = ref(false);
        const location = computed({
            get() {
                return root.$route.query.location;
            },
            set(location) {
                if (location !== root.$route.query.location) {
                    globalThis.$router.replace({
                        query: { ...root.$route.query, location }
                    });
                }
            }
        });
        // Default locations value (show spaces for all venues)
        if (!location.value) {
            location.value = 'all';
        }
        // Re-fetch new list of venues for the current page
        watch(location, (curr, prev) => {
            if (prev && curr.value !== prev.value) {
                if (!curr.value) {
                    location.value = 'all';
                }
            }
        }, { immediate: true });
        const page = computed({
            get() {
                return +root.$route.query.page || 1;
            },
            set(page) {
                if (page !== +root.$route.query.page && page > 1) {
                    globalThis.$router.replace({
                        query: { ...root.$route.query, page }
                    });
                }
            }
        });
        if (!page.value) {
            page.value = 1; // Default first page on component load
        }
        // Re-fetch new list of spaces for the current page
        watch(page, (curr, prev) => {
            if (prev && curr !== prev) {
                if (!curr) {
                    page.value = 1;
                }
            }
        }, { immediate: true });
        const organisationSlug = computed(() => {
            const { hash, slug } = root.$route.params;
            return `/${hash}/${slug}`;
        });
        const collectionQueryVariables = reactive({
            slug: organisationSlug.value
        });
        const { result, onResult, refetch } = useGetCollectionQuery(collectionQueryVariables);
        // Re-fetch organisation details on locale change
        watch(() => globalThis.$i18n.locale, (curr, prev) => {
            if (!prev)
                return;
            refetch(collectionQueryVariables);
        }, { immediate: true });
        const collection = computed(() => result?.value?.get_collection);
        const { injectStructuredData, ejectStructuredData } = useStructuredData();
        EventBus.$on('onBreadcrumbsReady', data => {
            injectStructuredData({
                '@context': 'https://schema.org',
                ...data
            });
        });
        onUnmounted(() => {
            ejectStructuredData();
        });
        onResult((result) => {
            if (!result?.data?.get_collection)
                return;
            useMeta({
                title: root.$i18n
                    .t('meta.organisation.title_tag', {
                    organisation_name: result.data.get_collection.collection_name
                })
                    .toString(),
                description: root.$i18n
                    .t('meta.organisation.description_tag', {
                    organisation_name: result.data.get_collection.collection_name
                })
                    .toString(),
                keywords: root.$i18n
                    .t('meta.general.meta_keywords', {
                    returnObjects: true
                })
                    .map((keyword) => `${keyword} ${result.data.get_collection.collection_name}`)
                    .join(','),
                url: `${SITE_URL}/${OrganisationRoutes.ORGANISATION}${result.data.get_collection.slug}`,
                imageUrl: result.data.get_collection.logo?.image_url || '',
                noIndex: page.value > 1
            });
        });
        // Mocked pages counter
        const pagesCount = computed(() => 2);
        /**
         * Advanced search
         * redirecting user to search page with prefilled query params
         */
        const redirectToSearch = () => {
            const routeData = {
                path: useGetLocalizedPath('search'),
                query: {
                    organisation: collection.value?.slug,
                    distance: true
                }
            };
            const routeURL = globalThis.$router.resolve(routeData).href;
            window.open(routeURL, '_blank');
        };
        /**
         * Venue selector
         */
        const selectVenue = (_location) => {
            page.value = 1;
            location.value = _location;
        };
        /**
         * CTA buttons on the right block. Scroll event
         */
        const onScroll = () => {
            const actionsElement = document.getElementById('left-block-actions');
            let viewportOffset = actionsElement?.getBoundingClientRect();
            if (viewportOffset && viewportOffset.top < 15 && !showButtons.value) {
                showButtons.value = true;
            }
            else if (viewportOffset &&
                viewportOffset.top >= 15 &&
                showButtons.value) {
                showButtons.value = false;
            }
        };
        /**
         * Adding scroll event listener
         */
        onMounted(() => {
            document.addEventListener('scroll', onScroll);
        });
        /**
         * Removing scroll event listener on destroy component lifecycle
         */
        onUnmounted(() => {
            document.removeEventListener('scroll', onScroll);
        });
        return {
            location,
            organisation: collection,
            orgData: collection,
            page,
            pagesCount,
            showButtons,
            onScroll,
            redirectToSearch,
            selectVenue
        };
    }
});
