import { defineComponent } from '@vue/composition-api';
export default defineComponent({
    props: {
        items: {
            type: Array,
            default: () => []
        },
        website: {
            type: String,
            default: ''
        }
    },
    setup() {
        const getClickableLink = (link) => link.startsWith('http://') || link.startsWith('https://')
            ? link
            : `http://${link}`;
        return {
            getClickableLink
        };
    }
});
