import { defineComponent } from '@vue/composition-api';
export default defineComponent({
    name: 'CustomButton',
    props: {
        iconClass: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
        fullMode: {
            type: Boolean,
            default: false
        }
    }
});
