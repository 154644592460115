import { defineComponent, computed } from '@vue/composition-api';
export default defineComponent({
    props: {
        title: {
            type: String,
            default: ''
        },
        selected: {
            type: String,
            default: ''
        },
        venues: {
            type: Array,
            default: () => []
        },
        cities: {
            type: Array,
            default: () => []
        }
    },
    setup(props, context) {
        const { root, emit } = context;
        const handleSelection = (citySlug) => {
            emit('selectVenue', 'all', citySlug);
        };
        const checkboxValue = computed(() => props.cities.some(el => el.value));
        const onVenueCardClick = (venue) => {
            emit('selectVenue', venue.venue_slug);
            root.$scrollTo('#venuesList', 500);
        };
        return {
            checkboxValue,
            handleSelection,
            onVenueCardClick
        };
    }
});
