import { computed, ref, reactive } from '@vue/composition-api';
import { cloneDeep, isEqual } from 'lodash';
export class PublicPageForm {
    backup;
    state;
    errors;
    constructor() {
        this.state = ref({
            id: 0,
            slug: null,
            name: null,
            aboutUs: null,
            promo: null,
            website: null,
            logo: null,
            logoId: null,
            socialMedia: [],
            isActivated: false
        });
        this.backup = ref({});
        this.backup.value = cloneDeep(this.state.value);
        this.errors = reactive({
            name: '',
            logo: ''
        });
    }
    /**
     * Fill the state with the data from the API
     * @param data
     */
    fillState(data) {
        this.state.value.id = data.collection_id;
        this.state.value.name = data.collection_name;
        this.state.value.aboutUs = data.about_us;
        this.state.value.promo = data.promo_text;
        this.state.value.logo = data.logo?.image_url;
        this.state.value.logoId = data.logo?.image_id;
        this.state.value.isActivated = data.has_public_page;
        this.state.value.slug = data.slug;
        this.state.value.website = data.website;
        this.state.value.socialMedia = data.social_media;
        this.backup.value = cloneDeep(this.state.value);
    }
    /**
     * Restore the state to the backup
     */
    restoreState() {
        this.state.value = cloneDeep(this.backup.value);
    }
    /**
     * Check if the state has changed from the backup
     */
    isStateChanged() {
        return !isEqual(this.state.value, this.backup.value);
    }
    /**
     * Check if the state logo has changed from the backup
     */
    isStateLogoChanged() {
        return this.state.value.logo !== this.backup.value.logo;
    }
    /**
     * Toggle the state isActivated
     */
    toggleStateIsActivated() {
        this.state.value.isActivated = !this.state.value.isActivated;
    }
    setInitialCompanyName() {
        const organisation = computed(() => globalThis.$store.getters['$_vendor/organisation']);
        this.state.value.name = organisation.value?.name;
        this.backup.value.name = organisation.value?.name;
    }
    resetErrors() {
        this.errors.name = '';
        this.errors.logo = '';
    }
}
