import { defineComponent, ref, computed } from '@vue/composition-api';
import Popover from '@/components/Popover.vue';
import { useGetSocialMediaQuery } from '@/generated-types/graphql.types';
import { mockedPlaceholders } from '@/Organisation/organisation.const';
export default defineComponent({
    components: {
        Popover
    },
    props: {
        socialMedia: {
            type: Array,
            default: () => []
        },
        website: {
            type: String,
            default: ''
        }
    },
    setup(props, context) {
        const { root, emit } = context;
        const { result } = useGetSocialMediaQuery();
        const allSocialMedia = computed(() => result.value?.get_all_social_media || []);
        const items = computed(() => [
            { social_media_id: 100, social_media_name: 'website2', label_id: 1 },
            ...allSocialMedia.value
        ]);
        const socialLink = ref('');
        const socialLabel = ref('');
        const socialId = ref(null);
        const socialInput = ref(undefined);
        const showInput = ref(false);
        const focused = ref(false);
        const editItem = (item) => {
            const smi = props.socialMedia.find(el => el.social_media_id === item.social_media_id);
            if (socialLabel.value && socialLabel.value === item.social_media_name) {
                socialLabel.value = '';
                socialId.value = null;
                showInput.value = false;
            }
            else {
                showInput.value = true;
                socialLabel.value = item.social_media_name;
                socialLink.value =
                    item.social_media_id === 100 ? props.website : smi?.social_media_link;
                socialId.value = item.social_media_id;
                root.$nextTick(() => {
                    if (socialInput.value) {
                        socialInput.value.$el.getElementsByTagName('input')[0].focus();
                    }
                });
            }
        };
        const onBlur = () => {
            focused.value = false;
            showInput.value = false;
            socialLabel.value = '';
        };
        const updateItemValue = (value) => {
            emit('update', { network: socialId.value, value });
        };
        const isSocialMediaExists = (socialMediaId) => {
            if (socialMediaId === 100) {
                return !!props.website;
            }
            else {
                return !!props.socialMedia.find(el => el.social_media_id === socialMediaId);
            }
        };
        return {
            allSocialMedia,
            focused,
            items,
            mockedPlaceholders,
            showInput,
            socialInput,
            socialLabel,
            socialLink,
            editItem,
            isSocialMediaExists,
            onBlur,
            updateItemValue
        };
    }
});
