import { render, staticRenderFns } from "./OrganisationVenuesSection.vue?vue&type=template&id=48bd1be6&scoped=true&"
import script from "./OrganisationVenuesSection.vue?vue&type=script&lang=ts&"
export * from "./OrganisationVenuesSection.vue?vue&type=script&lang=ts&"
import style0 from "./OrganisationVenuesSection.vue?vue&type=style&index=0&id=48bd1be6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48bd1be6",
  null
  
)

export default component.exports