import { defineComponent, computed, reactive, watch, ref } from '@vue/composition-api';
import OrganisationVenuesSection from '@/Organisation/components/OrganisationVenuesSection.vue';
import SpaceCard from '@/Search/components/SpaceCard.vue';
import PagePagination from '@/components/PagePagination.vue';
import { useGetCollectionSpacesQuery } from '@/generated-types/graphql.types';
import { useGetLocalizedPath } from '@/util/globalHelpers';
import { GeoPagesRoutes } from '@/GeoPages/geopages.const';
const PAGE_SIZE = Number(process.env.VUE_APP_SEO_PAGINATION);
export default defineComponent({
    components: {
        OrganisationVenuesSection,
        SpaceCard,
        PagePagination
    },
    props: {
        page: {
            type: Number,
            default: 1
        },
        venueName: {
            type: String,
            default: 'all'
        },
        organisation: {
            type: Object,
            default: () => ({})
        }
    },
    setup(props, context) {
        const { root, emit } = context;
        const collectionSpacesQueryVariables = reactive({
            collection_id: props.organisation.collection_id,
            pagination: {
                page: props.page,
                size: PAGE_SIZE
            }
        });
        const getVenueId = () => {
            const venue = props.organisation.venues.find(el => el.venue_slug === props.venueName);
            return venue?.venue_id;
        };
        if (props.venueName !== 'all') {
            collectionSpacesQueryVariables.venue_id = getVenueId();
        }
        const listTitle = computed(() => {
            const venue = props.organisation.venues.find(el => el.venue_slug === props.venueName);
            return venue?.venue_name
                ? root
                    .$t('organisation.spaces_title', { venue_name: venue.venue_name })
                    .toString()
                : root
                    .$t('organisation.spaces_title_all', {
                    collection_name: props.organisation.collection_name
                })
                    .toString();
        });
        const venueCity = computed(() => {
            const venue = props.organisation.venues.find(el => el.venue_slug === props.venueName);
            const citiesEnabled = uniqueCities.value.filter(el => el.value);
            if (venue)
                return {
                    title: venue.venue_geo.city.seo_entity_name,
                    slug: venue.venue_geo.city.seo_entity_slug
                };
            else if (citiesEnabled.length === 1)
                return { title: citiesEnabled[0].label, slug: citiesEnabled[0].slug };
            return null;
        });
        const { result, refetch } = useGetCollectionSpacesQuery(collectionSpacesQueryVariables, {
            fetchPolicy: 'no-cache'
        });
        // Re-fetch new list of venues for the current page
        watch(() => props.page, (curr, prev) => {
            if (prev && curr !== prev) {
                if (!curr) {
                    emit('input', 1);
                }
                collectionSpacesQueryVariables.pagination.page = curr || 1;
                refetch(collectionSpacesQueryVariables);
            }
        }, { immediate: true });
        watch(() => props.venueName, (curr, prev) => {
            if (!prev) {
                return;
            }
            if (curr === 'all') {
                collectionSpacesQueryVariables.collection_id =
                    props.organisation.collection_id;
                collectionSpacesQueryVariables.venue_id = null;
                refetch(collectionSpacesQueryVariables);
            }
            else {
                const venue = props.organisation.venues.find(el => el.venue_slug === curr);
                uniqueCities.value.map(el => (el.value =
                    el.label === (venue?.venue_geo.city.seo_entity_name || '')));
                if (venue) {
                    collectionSpacesQueryVariables.venue_id = venue.venue_id;
                }
                collectionSpacesQueryVariables.pagination.page = props.page;
                refetch(collectionSpacesQueryVariables);
            }
        }, { immediate: true });
        const pagesCount = computed(() => result.value?.get_collection_spaces.total_pages);
        const citiesList = ref(props.organisation.venues.map(el => ({
            label: el.venue_geo.city.seo_entity_name,
            slug: el.venue_geo.city.seo_entity_slug,
            value: false
        })));
        const uniqueCities = ref(citiesList.value
            .filter((el, i) => citiesList.value.findIndex(obj => obj.label === el.label) === i)
            .sort((a, b) => (a.label > b.label ? 1 : -1)));
        const filteredVenues = computed(() => {
            const checkboxValue = uniqueCities.value.some(el => el.value);
            if (!checkboxValue) {
                return props.organisation.venues;
            }
            else {
                return props.organisation.venues.filter(el => citiesList.value.find(city => city.label === el.venue_geo.city.seo_entity_name)?.value);
            }
        });
        const spaces = computed(() => {
            if (!result.value?.get_collection_spaces.result)
                return [];
            const spacesFromResponse = result.value.get_collection_spaces.result.map((el) => ({
                spaceUrl: el.space_url,
                spaceName: el.space_name,
                internalName: el.internal_name,
                venueId: el.venue.venue_id,
                images: el.space_images
                    .map(el => el && el.image_url)
                    .filter(Boolean),
                halfDayPrice: el.half_day_price,
                bookingType: el.space_booking_type,
                venueName: el.venue.venue_name,
                isAfterpayAllowed: el.is_afterpay_allowed,
                activities: el.space_categories.map(category => category && category.category_id),
                attendees: {
                    minGuests: el.min_guests,
                    maxGuests: el.max_guests
                },
                isNew: el.is_new,
                isFavorite: el.is_favorite
            }));
            if (props.venueName === 'all') {
                return spacesFromResponse.filter(el => filteredVenues.value.some(v => v.venue_id === el.venueId));
            }
            return spacesFromResponse;
        });
        const selectVenue = (location, citySlug) => {
            uniqueCities.value.map(el => (el.value = el.slug === citySlug));
            emit('selectVenue', location);
        };
        return {
            filteredVenues,
            listTitle,
            pagesCount,
            spaces,
            uniqueCities,
            venueCity,
            GeoPagesRoutes,
            selectVenue,
            useGetLocalizedPath
        };
    }
});
