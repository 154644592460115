import EventBus from '@/shared/services/eventBus';
import { EVENTBUS_EVENTS } from '@/shared/const';
const asyncPageMixin = {
    data() {
        return {
            dataLoaded: false
        };
    },
    methods: {
        async callAPIAction(action, param) {
            if (action === '$_vendor/initVendor') {
                try {
                    const result = await globalThis.$store.dispatch('$_vendor/initVendor', param);
                    this.dataLoaded = true;
                    return result;
                }
                catch (e) {
                    this.dataLoaded = true;
                    if (e.status !== 404) {
                        EventBus.$emit(EVENTBUS_EVENTS.API_ERROR, e);
                    }
                }
            }
            else {
                const result = await globalThis.$store.dispatch(action, param);
                this.dataLoaded = true;
                return result;
            }
        }
    }
};
export default asyncPageMixin;
