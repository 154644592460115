import { defineComponent } from '@vue/composition-api';
import { ROUTE_LIST_NAMES } from '@/config/router/appRoutes';
export default defineComponent({
    setup() {
        const continueOnboarding = () => {
            globalThis.$router.push({ name: ROUTE_LIST_NAMES.ONBOARDING.VENUE.MAIN });
        };
        const goToProfile = () => {
            globalThis.$router.push({ name: ROUTE_LIST_NAMES.PROFILE.ORGANISATION });
        };
        return {
            continueOnboarding,
            goToProfile
        };
    }
});
