var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"wrapper__label"},[_c('span',{staticClass:"wrapper__label-title"},[_vm._v(_vm._s(_vm.$t('organisation.editing_page.social_links')))]),_c('Popover',{attrs:{"offset":[100, 10]}},[_c('div',[_c('i',{staticClass:"fa-solid fa-circle-question fa-sm"})]),_c('div',{staticClass:"social-media-question",attrs:{"slot":"content"},slot:"content"},[_vm._v(" "+_vm._s(_vm.$t('organisation.editing_page.social_links_popup'))+" ")])])],1),_c('div',{staticClass:"social-networks-edit"},_vm._l((_vm.items),function(item){return _c('div',{key:item.social_media_id,staticClass:"social-networks-edit__item",on:{"click":function($event){return _vm.editItem(item)}}},[_c('img',{class:[
          'social-networks-edit__icon',
          { active: _vm.socialLabel === item.social_media_name },
          { filled: _vm.isSocialMediaExists(item.social_media_id) }
        ],attrs:{"src":("https://media.wemeet.nl/gallery/assets/common/" + (item.social_media_name) + ".png?tr=w-50,h-50"),"alt":item.social_media_name,"loading":"lazy"}}),_c('div',{class:[
          'arrow-down',
          { active: _vm.socialLabel === item.social_media_name },
          { focused: _vm.focused }
        ]},[_c('div',{staticClass:"inner-arrow"})])])}),0),_c('div',{class:['social-media-input', { active: _vm.showInput }]},[_c('WMInput',{ref:"socialInput",attrs:{"data-gtm":"collection_page_social_network_name_input","value":_vm.socialLink,"placeholder":_vm.mockedPlaceholders[_vm.socialLabel]},on:{"focus":function($event){_vm.focused = true},"blur":_vm.onBlur,"input":_vm.updateItemValue}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }