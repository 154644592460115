var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.user)?_c('PageLoadingBar'):_vm._e(),(
      _vm.organisation &&
      _vm.organisation.id &&
      !_vm.isProfilePage &&
      !_vm.state.resultPopupVisible
    )?_c('OrganisationCreated'):(_vm.user)?_c('div',{class:{
      column: !_vm.$route.meta.embed,
      'is-6': !_vm.$route.meta.embed,
      'organisation-details': true,
      full: _vm.$route.meta.embed
    }},[(_vm.form.organisationRating !== null)?_c('div',{staticClass:"organisation-details-rating"},[_c('div',{staticClass:"organisation-details-rating-title"},[_c('h1',[_vm._v(" "+_vm._s(_vm.$t('onboarding.organisation_details.organisation_rating.title'))+" ")]),_c('Popover',{attrs:{"offset":[78, -140]}},[_c('div',[_c('i',{staticClass:"fa-solid fa-circle-question fa-sm"})]),_c('div',{staticClass:"wrapper",attrs:{"slot":"content"},slot:"content"},[_c('div',{staticClass:"popover-content",domProps:{"innerHTML":_vm._s(
                _vm.$t(
                  'onboarding.organisation_details.organisation_rating.popover_text'
                )
              )}})])])],1),_c('OrganisationRating',{attrs:{"organisation-rating":_vm.form.organisationRating}})],1):_vm._e(),(!_vm.isProfilePage)?_c('div',{staticClass:"organisation-details-rating-header"},[_c('h1',{staticClass:"organisation-details-rating-header-label"},[_vm._v(" "+_vm._s(_vm.$t('onboarding.organisation_details.label_header'))+" ")]),_c('h2',{staticClass:"switcher"},[_vm._v(" "+_vm._s(_vm.$t('onboarding.organisation_details.switch_to_booker'))+" "),_c('i',{staticClass:"fa-solid fa-arrow-right-arrow-left fa-2xs"}),_c('span',{staticClass:"switcher--role",staticStyle:{"margin-left":"5px"},on:{"click":_vm.switchToBooker}},[_vm._v(_vm._s(_vm.$t('common.booker')))])])]):_vm._e(),_c('form',{on:{"submit":function($event){$event.preventDefault();}}},[(_vm.address)?_c('AddressProvider',{attrs:{"data-gtm":"organisation_details_form_address_input","company-name":_vm.address},on:{"loaded":_vm.setCompaniesSuggestions}}):_vm._e(),_c('WMInput',{attrs:{"data-gtm":"organisation_details_form_name_input","disabled":!_vm.isUpdateAllowed,"required":"","label":_vm.$t(
            'onboarding.organisation_details.input_label_organisation_name'
          ).toString(),"placeholder":_vm.$t(
            'onboarding.organisation_details.input_placeholder_organisation_name'
          ).toString(),"no-results":_vm.$t(
            'onboarding.organisation_details.input_placeholder_no_results'
          ).toString(),"autocomplete":_vm.companiesSuggestions,"error":_vm.form.errors('name')},on:{"blur":_vm.onOrganisationNameFocusOff,"focus":_vm.onOrganisationNameFocusOn,"input":_vm.onAddressInput,"autocompleteSelected":_vm.addressAutocompleteSelected,"autocompleteDeselected":function (val) { return (_vm.companiesSuggestions[val].selected = false); }},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),(_vm.form.coc)?_c('WMInput',{attrs:{"data-gtm":"organisation_details_form_kvk_input","disabled":"","label":_vm.$t(
            'onboarding.organisation_details.input_label_coc_number'
          ).toString(),"placeholder":_vm.$t(
            'onboarding.organisation_details.input_placeholder_coc_number'
          ).toString(),"error":_vm.form.errors('coc', 'organisationAddress'),"input-type":'number',"max-length":9},model:{value:(_vm.form.coc),callback:function ($$v) {_vm.$set(_vm.form, "coc", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.coc"}}):_vm._e(),(_vm.form.organisationAddress)?_c('WMInput',{attrs:{"data-gtm":"organisation_details_form_org_address_input","disabled":"","label":_vm.$t(
            'onboarding.organisation_details.input_label_organisation_address'
          ).toString()},model:{value:(_vm.form.organisationAddress),callback:function ($$v) {_vm.$set(_vm.form, "organisationAddress", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.organisationAddress"}}):_vm._e(),(_vm.form.coc)?_c('WMInput',{directives:[{name:"mask",rawName:"v-mask",value:('NL#########B##'),expression:"'NL#########B##'"}],attrs:{"id":"vatNumber","data-gtm":"organisation_details_form_btw_input","value":_vm.form.vatNumber ? _vm.form.vatNumber.toUpperCase() : _vm.form.vatNumber,"disabled":!_vm.isUpdateAllowed,"label":_vm.$t(
            'onboarding.organisation_details.input_label_vat_number'
          ).toString(),"placeholder":_vm.$t(
            'onboarding.organisation_details.input_placeholder_vat_number'
          ).toString(),"error":_vm.form.errors('vatNumber') || _vm.vatErr,"max-length":14,"required":""},on:{"input":function (val) { return (_vm.form.vatNumber = val); }}}):_vm._e(),_c('FormSelect',{attrs:{"placeholder":_vm.$t(
            'onboarding.organisation_details.input_placeholder_vat_rate'
          ).toString(),"disabled":!_vm.isUpdateAllowed,"options":_vm.vatRates,"error":_vm.form.errors('vatRate'),"label":_vm.$t(
            'onboarding.organisation_details.select_label_vat_rate'
          ).toString(),"required":""},model:{value:(_vm.form.vatRate),callback:function ($$v) {_vm.$set(_vm.form, "vatRate", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.vatRate"}}),(_vm.form.organisationAddress)?_c('AddressAutocomplete',{attrs:{"id":"financePlaceId","form":_vm.form,"inline":false,"entity":"organisation","field-name":"financeAddress","disabled":!_vm.isUpdateAllowed || _vm.state.sameAddress,"show-sugestions":_vm.isUpdateAllowed || !_vm.state.sameAddress},on:{"change":_vm.organisationAddressChange,"addressChange":_vm.updatedOrgAddress,"clear":function () { return (_vm.form.financePlaceId = ''); },"setAddress":_vm.setupAddress}}):_vm._e(),(_vm.form.organisationAddress)?_c('FormCheckbox',{attrs:{"disabled":!_vm.isUpdateAllowed},model:{value:(_vm.state.sameAddress),callback:function ($$v) {_vm.$set(_vm.state, "sameAddress", $$v)},expression:"state.sameAddress"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t( 'onboarding.organisation_details.checkbox_label_same_as_organisation_address' ))+" ")])]):_vm._e(),_c('WMInput',{attrs:{"data-gtm":"organisation_details_form_finance_email_input","required":"","disabled":!_vm.isUpdateAllowed || _vm.state.sameEmail,"label":_vm.$t(
            'onboarding.organisation_details.input_label_finance_email'
          ).toString(),"placeholder":_vm.$t(
            'onboarding.organisation_details.input_placeholder_finance_email'
          ).toString(),"error":_vm.form.errors('financeEmail')},model:{value:(_vm.form.financeEmail),callback:function ($$v) {_vm.$set(_vm.form, "financeEmail", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.financeEmail"}}),_c('FormCheckbox',{staticClass:"email-checkbox",attrs:{"disabled":!_vm.isUpdateAllowed},on:{"click":_vm.onSameEmailClick},model:{value:(_vm.state.sameEmail),callback:function ($$v) {_vm.$set(_vm.state, "sameEmail", $$v)},expression:"state.sameEmail"}},[_vm._v(" "+_vm._s(_vm.$t( 'onboarding.organisation_details.checkbox_label_same_as_account_email' ))+" ")]),(!_vm.isProfilePage)?_c('WMButton',{staticClass:"main-button",attrs:{"data-gtm":"organisation_details_form_continue_button","disabled":!_vm.isContinueButtonDisabled,"icon-class-name":("" + (!_vm.isContinueButtonDisabled ? '' : 'fa-solid fa-arrow-right fa-lg'))},on:{"click":_vm.nextPage}},[_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v(_vm._s(_vm.$t('onboarding.organisation_details.button_label_primary')))])]):_vm._e()],1),(_vm.state.resultPopupVisible)?_c('ResultPopup',{directives:[{name:"scroll-lock",rawName:"v-scroll-lock",value:(_vm.state.resultPopupVisible),expression:"state.resultPopupVisible"}],on:{"create":_vm.createVenue,"close":_vm.closeResultPopup}}):_vm._e()],1):_vm._e(),(_vm.onProfilePage)?_c('SaveFooter',{attrs:{"on-save":_vm.onSaveChanges,"on-close":_vm.onClose,"is-changed":_vm.isSettingsChanged,"is-saved":_vm.isChangesSaved,"on-revert":_vm.onRevert}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }