//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ModalPopup from '@/shared/components/ModalPopup.vue';
import SkipLinks from '@/shared/components/SkipLinks.vue';

export default {
  components: {
    ModalPopup,
    SkipLinks
  }
};
